<template>
  <div class="w-100 text-center">
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      v-b-tooltip.hover
      title="Modifier"
      variant="flat-warning"
      class="btn-icon rounded-circle"
    >
      <b-link
        v-b-modal="`modal-edit-serialnumber-${serialNumber.id}`"
      >
        <feather-icon
          icon="SettingsIcon"
          size="20"
        />
      </b-link>
    </b-button>
    <b-modal
      :id="`modal-edit-serialnumber-${serialNumber.id}`"
      cancel-variant="outline-primary"
      ok-title="Modifier"
      cancel-title="Annuler"
      centered
      title="Modifier le numéro de série"
      @ok="editSerialnumber(serialNumber)"
    >
      <b-form
        @submit.prevent
      >
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Année"
              label-for="h-year"
            >
              <b-form-input
                v-model="serialNumber.year"
                name="h-year"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Modèle"
              label-for="h-model"
            >
              <b-form-input
                v-model="serialNumber.model"
                name="h-model"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Série"
              label-for="h-serial"
            >
              <b-form-input
                v-model="serialNumber.serial"
                name="h-serial"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Utilisé"
              label-for="h-isUse"
            >
              <b-form-checkbox
                v-model="serialNumber.isUse"
                name="h-isUse"
                switch
                inline
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="10">
            <b-form-group
              label="Commentaire"
              label-for="h-comment"
            >
              <b-form-textarea
                v-model="serialNumber.comment"
                name="h-comment"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup,
  BCol, BRow, VBTooltip, BFormCheckbox, BFormTextarea,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BFormCheckbox,
    BFormTextarea,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    serialNumber: {
      type: Object,
      default: null,
    },
  },
  methods: {
    editSerialnumber(data) {
      console.log('data create', data)
      this.$store.dispatch('serialnumber/updateSerialnumber', { serialId: data.id, body: data })
        .then(() => this.$emit('updateSerial', data))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
