<template>
  <div
    v-if="$store.state.auth.role === 1"
  >
    <serial-filters
      :year-options="yearList"
      :model-options="modelList"
      :year-value.sync="yearValue"
      :model-value.sync="modelValue"
    />
    <b-card
      no-body
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <modal-create-serialnumber />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-table
            v-if="serialnumberList.length > 0"
            class="position-relative"
            :items="serialnumberList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Aucun contrôle trouvé"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(construc)="data">
              <b-link
                class="font-weight-bold d-block link-hover"
                style="cursor: inherit;"
              >
                LU{{ data.item.year }}{{ data.item.model }}{{ twoDigit(data.item.serial) }}
              </b-link>
            </template>
            <template #cell(year)="data">
              <small>
                {{ data.item.year }}
              </small>
            </template>

            <template #cell(model)="data">
              <small>
                {{ data.item.model }}
              </small>
            </template>

            <template #cell(serial)="data">
              <small>
                {{ data.item.serial }}
              </small>
            </template>

            <template #cell(isUse)="data">
              <b-avatar
                :variant="`light-${resolveisUseVariant(data.item.isUse)}`"
              >
                <feather-icon
                  :icon="data.item.isUse ? 'CheckIcon' : 'XIcon'"
                />
              </b-avatar>
            </template>

            <template #cell(comment)="data">
              <small>
                {{ data.item.comment }}
              </small>
            </template>

            <template #cell(action)="data">
              <div class="d-flex">
                <modal-edit-serialnumber :serial-number="data.item" />
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-tooltip.hover
                  title="Supprimer"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  @click="deleteSerial(data.item.id)"
                >
                  <feather-icon
                    icon="DeleteIcon"
                    size="20"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BLink, BButton, VBTooltip,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ModalCreateSerialnumber from './ModalCreateSerialnumber.vue'
import SerialFilters from './SerialFilters.vue'
import ModalEditSerialnumber from './ModalEditSerialnumber.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BLink,
    BButton,
    ModalCreateSerialnumber,
    SerialFilters,
    BAvatar,
    ModalEditSerialnumber,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      serialnumberList: [],
      tableColumns: [
        { key: 'construc', label: 'N°', sortable: true },
        { key: 'year', label: 'Année', sortable: true },
        { key: 'model', label: 'Modèle', sortable: true },
        { key: 'serial', label: 'Série', sortable: true },
        { key: 'isUse', label: 'Utilisé', sortable: true },
        { key: 'comment', label: 'Commentaire', sortable: true },
        {
          key: 'action', label: 'Actions', sortable: false, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      sortBy: 'model',
      isSortDirDesc: true,
      // filters
      yearList: [],
      modelList: [],
      yearValue: null,
      modelValue: null,
    }
  },
  watch: {
    yearValue() {
      this.filterSerialnumber()
    },
    modelValue() {
      this.filterSerialnumber()
    },
  },
  created() {
    this.getSerialnumbers()
  },
  methods: {
    getSerialnumbers() {
      this.serialnumberList = store.state.serialnumber.serialnumbersList.sort((a, b) => a.model - b.model)
      const yearArray = []
      const modelArray = []
      this.serialnumberList.forEach(elem => {
        yearArray.push(elem.year)
        modelArray.push(elem.model)
      })
      this.yearList = [...new Set(yearArray)]
      this.modelList = [...new Set(modelArray)]
    },
    twoDigit(n) {
      return (n < 10 ? '0' : '') + n
    },
    filterSerialnumber() {
      this.serialnumberList = store.state.serialnumber.serialnumbersList.sort((a, b) => a.model - b.model)
      if (this.yearValue) {
        this.serialnumberList = this.serialnumberList.filter(serial => serial.year === this.yearValue)
      }
      if (this.modelValue) {
        this.serialnumberList = this.serialnumberList.filter(serial => serial.model === this.modelValue)
      }
    },
    deleteSerial(id) {
      this.$swal({
        title: 'Êtes-vous sûr de vouloir supprimer ?',
        text: 'Cette action est irréversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('serialnumber/deleteSerialnumber', id)
          this.$swal({
            icon: 'success',
            title: 'Supprimé!',
            text: 'Le n° de série a bien été supprimé',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
        this.getSerialnumbers()
      })
    },
    resolveisUseVariant(data) {
      if (data) return 'success'
      if (!data) return 'danger'
      return 'dark'
    },
  },
}
</script>
