<template>
  <div>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-create-serialnumber
      v-b-tooltip.hover
      title="Ajouter"
      variant="outline-primary"
      class="mt-2 mb-2 mr-2"
    >
      Ajouter un n° de serie
    </b-button>
    <b-modal
      id="modal-create-serialnumber"
      cancel-variant="outline-primary"
      ok-title="Ajouter"
      cancel-title="Annuler"
      centered
      title="Nouveau numéro de série"
      @ok="addSerialnumber(data)"
    >
      <b-form
        @submit.prevent
      >
        <b-row>
          <b-col cols="4">
            <b-form-group
              label="Année"
              label-for="h-year"
            >
              <b-form-input
                v-model="data.year"
                name="h-year"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Modèle"
              label-for="h-model"
            >
              <b-form-input
                v-model="data.model"
                name="h-model"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Série"
              label-for="h-serial"
            >
              <b-form-input
                v-model="data.serial"
                name="h-serial"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group
              label="Utilisé"
              label-for="h-isUse"
            >
              <b-form-checkbox
                v-model="data.isUse"
                name="h-isUse"
                switch
                inline
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="10">
            <b-form-group
              label="Commentaire"
              label-for="h-comment"
            >
              <b-form-textarea
                v-model="data.comment"
                name="h-comment"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup,
  BCol, BRow, VBTooltip, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BCol,
    BRow,
    BFormCheckbox,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {},
    }
  },
  methods: {
    addSerialnumber(data) {
      this.$store.dispatch('serialnumber/createSerialnumber', data)
        .then(() => {
          this.data = {}
          this.$router.go()
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
